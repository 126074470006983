<template>
	<div v-if="!contentLoading">
		<div class="title-bar">
			<span class="title-bar_content">基本信息</span>
		</div>
		<div class="form-content">
			<a-form-model ref="form" :model="form" :label-col="{span: 6}" :wrapper-col="{span: 14}" :rules="rules">
				<a-form-model-item label="店铺名称" prop="title">
					<div style="position: relative">
						<a-input v-model="form.title" placeholder="请输入店铺名称" disabled />
						<div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 999" @click="onShowTips"></div>
					</div>
				</a-form-model-item>

				<a-form-model-item label="联系电话" prop="phone">
					<a-input v-model="form.phone" placeholder="请输入联系电话" />
				</a-form-model-item>
				<a-form-model-item label="餐厅类型" prop="category">
					<a-select mode="multiple" v-model="form.category" placeholder="请选择餐厅类型">
						<a-select-option v-for="item in category_options" :key="item.value" :value="item.value">
							{{ item.label }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="菜系" prop="cuisine">
					<a-select mode="multiple" v-model="form.cuisine" placeholder="请选择菜系">
						<a-select-option v-for="item in cuisine_options" :key="item.value" :value="item.value">
							{{ item.label }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="开始营业日" prop="business_time.start_date">
					<a-select allowClear placeholder="请选择开始营业日" style="width: 100%" v-model="form.business_time.start_date">
						<a-select-option v-for="item in week" :key="item.i">
							{{ item.we }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="结束营业日" prop="business_time.end_date">
					<a-select allowClear placeholder="请选择结束营业日" style="width: 100%" v-model="form.business_time.end_date">
						<a-select-option v-for="item in week" :key="item.i">
							{{ item.we }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="营业时间" prop="business_time.isSingle">
					<a-radio-group v-model="form.business_time.isSingle" @change="validatorTime">
						<a-radio :value="1">单营业时间段</a-radio>
						<a-radio :value="2">双营业时间段</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<template v-if="form.business_time.isSingle === 2">
					<a-form-model-item label="上午开始营业时间" prop="business_time.start_time_am">
						<a-time-picker v-model="form.business_time.start_time_am" placeholder="请选择上午开始营业时间" format="HH:mm" valueFormat="HH:mm" style="width: 100%" />
					</a-form-model-item>
					<a-form-model-item label="上午结束营业时间" prop="business_time.end_time_am">
						<a-time-picker v-model="form.business_time.end_time_am" placeholder="请选择上午结束营业时间" format="HH:mm" valueFormat="HH:mm" style="width: 100%" />
					</a-form-model-item>
					<a-form-model-item label="下午开始营业时间" prop="business_time.start_time_pm">
						<a-time-picker v-model="form.business_time.start_time_pm" placeholder="请选择下午开始营业时间" format="HH:mm" valueFormat="HH:mm" style="width: 100%" />
					</a-form-model-item>
					<a-form-model-item label="下午结束营业时间" prop="business_time.end_time_pm">
						<a-time-picker v-model="form.business_time.end_time_pm" placeholder="请选择下午结束营业时间" format="HH:mm" valueFormat="HH:mm" style="width: 100%" />
					</a-form-model-item>
				</template>
				<template v-if="form.business_time.isSingle === 1">
					<a-form-model-item label="开始营业时间" prop="business_time.start_time">
						<a-time-picker v-model="form.business_time.start_time" placeholder="请选择开始营业时间" format="HH:mm" valueFormat="HH:mm" style="width: 100%" />
					</a-form-model-item>
					<a-form-model-item label="结束营业时间" prop="business_time.end_time">
						<a-time-picker v-model="form.business_time.end_time" placeholder="请选择结束营业时间" format="HH:mm" valueFormat="HH:mm" style="width: 100%" />
					</a-form-model-item>
				</template>
				<div class="title-bar">
					<span class="title-bar_content">门店照片及设置</span>
				</div>
				<div class="form-content">
					<a-form-model-item label="店铺logo" prop="logo" ref="logo">
						<img-uploader v-model="form.logo" @success="$refs.logo.onFieldBlur()" @remove="$refs.logo.onFieldBlur()" />
					</a-form-model-item>
					<a-form-model-item label="VR" prop="vr_url" ref="vr_url">
						<a-input v-model="form.vr_url.url" placeholder="请输入VR链接" />
						<img-uploader class="mt-4" v-if="form.vr_url.url" v-model="form.vr_url.picture" @success="$refs.vr_url.onFieldBlur()" @remove="$refs.vr_url.onFieldBlur()" />
					</a-form-model-item>
					<a-form-model-item label="门店照片" prop="images" ref="img">
						<img-uploader v-model="form.images" model-type="Arr" :max="999" :showUploadList="false" :multiple="true" @success="$refs.img.onFieldBlur()" @remove="$refs.img.onFieldBlur()" />
					</a-form-model-item>
					<a-form-model-item label="门店视频" prop="video">
						<video-upload v-model="form.video" @remove="onRemoveStoreVideo()" />
					</a-form-model-item>
					<a-form-model-item label="选择门店位置">
						<a-button type="primary" @click="openMap">选择门店位置</a-button>
					</a-form-model-item>
					<a-form-model-item
						label="门店地址"
						prop="address.name"
						:rules="{
							required: true,
							message: '请选择门店地址',
							trigger: ['change', 'blur']
						}">
						<a-input v-model="form.address.name" />
					</a-form-model-item>
					<a-form-model-item
						label="门店经度"
						prop="address.lng"
						:rules="{
							required: true,
							message: '请选择门店地址'
						}">
						<a-input v-model="form.address.lng" disabled />
					</a-form-model-item>
					<a-form-model-item
						label="门店纬度"
						prop="address.lat"
						:rules="{
							required: true,
							message: '请选择门店地址'
						}">
						<a-input v-model="form.address.lat" disabled />
					</a-form-model-item>
					<a-form-model-item label="选择停车场位置">
						<a-button type="primary" @click="openMap(1)">选择停车场位置</a-button>
						<a-button type="default" style="margin-left: 10px" v-if="form.park_position.name" @click="onDeletePark">删除</a-button>
					</a-form-model-item>
					<a-form-model-item label="停车场地址" prop="park_position.name">
						<a-input v-model="form.park_position.name" />
					</a-form-model-item>
					<a-form-model-item label="停车场经度" prop="park_position.lng">
						<a-input v-model="form.park_position.lng" disabled />
					</a-form-model-item>
					<a-form-model-item label="停车场纬度" prop="park_position.lat">
						<a-input v-model="form.park_position.lat" disabled />
					</a-form-model-item>
				</div>

				<div class="title-bar">
					<span class="title-bar_content">餐厅信息</span>
				</div>
				<div class="form-content">
					<a-form-model-item label="餐厅理念" prop="concept">
						<a-input type="textarea" v-model="form.concept" placeholder="请输入餐厅理念(不超过26个字)" :max-length="26" />
					</a-form-model-item>

					<a-form-model-item label="人均消费" prop="cpi">
						<a-input-number class="input_cpi" suffix="元起" v-model="form.cpi" placeholder="请输入人均消费" :formatter="cpi => (/^\d+$/.test(cpi) ? cpi : cpi.slice(0, -1))" />
					</a-form-model-item>

					<a-form-model-item label="餐厅招牌菜" prop="signature_dish_picture">
						<a-button type="primary" style="width: 140px; height: 42px" @click="dishesVisible = true"> 上传招牌菜 </a-button>
						<div class="flex flex-wrap mt-4" id="shopSign" ref="shopSign" @click.stop>
							<div class="dish-item imgItem" v-for="(item, index) in form.signature_dish_picture" :key="item.picture">
								<img :src="item.picture" alt="菜品图片" />
								<div class="dish-name">{{ item.name }}</div>
								<div class="dish-item-mask">
									<!-- <a-icon
										type="eye"
										class="mr-4 text-white cursor-pointer"
										@click="
											handlePreview({ url: item.picture })
										" /> -->
									<a-icon type="eye" class="mr-4 text-white cursor-pointer" @click="handleDishesPreview(item, index)" />
									<a-icon type="delete" class="text-white cursor-pointer" @click="delDish(index)" />
								</div>
							</div>
						</div>
					</a-form-model-item>

					<a-form-model-item ref="shop_say" label="商家有话说" prop="shop_say">
						<wang-editor ref="editor" v-model="form.shop_say" @change="handleShopSayChange"> </wang-editor>
						<template #help>
							<div class="flex text-sm mb-4 form-item-mark">
								<div class="text-theme-red mr-2">注:</div>
								<div>
									<p>1、图片格式必须为jpg/png，大小为5M以下</p>
									<p>2、视频格式必须为mp4/flv，大小为100M以下</p>
									<p>3、建议尺寸：宽460px，高300px</p>
								</div>
							</div>
						</template>
					</a-form-model-item>

					<a-form-model-item ref="park" label="停车指引" prop="park">
						<wang-editor ref="editor" v-model="form.park" @change="handleParkChange"> </wang-editor>
						<template #help>
							<div class="flex text-sm mb-4 form-item-mark">
								<div class="text-theme-red mr-2">注:</div>
								<div>
									<p>1、图片格式必须为jpg/png，大小为5M以下</p>
									<p>2、视频格式必须为mp4/flv，大小为100M以下</p>
									<p>3、建议尺寸：宽460px，高300px</p>
								</div>
							</div>
						</template>
					</a-form-model-item>

					<a-form-model-item label="店铺自定义颜色" prop="custom_color">
						<!-- :rules="{ required: true, message: '请选择店铺自定义颜色' }" -->
						<div class="flex flex-wrap">
							<div
								v-for="(item, index) in customColor"
								@click="
									form.custom_color = item.value
									form.theme = item.theme
								"
								class="custom-color-item"
								:class="{
									'custom-color-item-active': form.custom_color == item.value
								}"
								:key="index"
								style="margin-top: 10px">
								<div
									class="color-block"
									:style="{
										'background-color': item.color
									}"></div>
								<span>{{ item.name }}</span>
							</div>
						</div>
					</a-form-model-item>

					<a-form-model-item label="默认邀请函样式" prop="default_order_theme">
						<div class="flex flex-wrap">
							<div
								v-for="(item, index) in invitationColor"
								@click="form.default_order_theme = item.value"
								class="custom-color-item"
								:class="{
									'custom-color-item-active': form.default_order_theme == item.value
								}"
								:key="index"
								style="margin-top: 10px">
								<div
									class="color-block"
									:style="{
										'background-color': item.color
									}"></div>
								<span>{{ item.name }}</span>
							</div>
						</div>
					</a-form-model-item>
				</div>

				<div class="title-bar">
					<span class="title-bar_content">问答区</span>
				</div>
				<div class="form-content">
					<div v-for="(item, index) in form.questions" :key="index">
						<a-form-model-item label="问" class="delFormItem" :prop="`questions.${index}.question`">
							<a-input :placeholder="form.questions[index].placeholder || '请输入问题'" v-model="form.questions[index].question" />
							<img src="@/assets/delete.png" class="delItem" @click="questionDel(index)" />
						</a-form-model-item>
						<a-form-model-item label="答" :prop="`questions.${index}.answer`">
							<a-input type="textarea" placeholder="请输入问题答案" v-model="form.questions[index].answer" />
						</a-form-model-item>
					</div>

					<a-form-model-item label="问题">
						<a-button type="primary" @click="addQuestuin">添加问题</a-button>
					</a-form-model-item>
				</div>

				<div class="title-bar">
					<span class="title-bar_content">添加小票机</span>
				</div>
				<div class="form-content">
					<a-form-model-item
						label="编码SN："
						prop="feie.sn"
						:rules="{
							trigger: ['change', 'blur'],
							max: 9,
							min: 9,
							message: '请输入打印机编码(9位数)'
						}">
						<a-input v-model="form.feie.sn" placeholder="请输入打印机编码(9位数)" />
					</a-form-model-item>
					<a-form-model-item
						label="识别码KEY:"
						prop="feie.key"
						:rules="{
							trigger: ['change', 'blur'],
							max: 8,
							min: 8,
							message: '请输入底部标签有KEY(8位数)'
						}">
						<a-input v-model="form.feie.key" placeholder="底部标签有KEY(8位字符)，标签无识别码的客户可以通过客服咨询获得" />
					</a-form-model-item>
					<a-form-model-item label="打印联数：" prop="feie.copies">
						<a-input-number style="width: 100%" v-model="form.feie.copies" placeholder="请输入打印联数" />
					</a-form-model-item>

					<a-form-model-item :wrapper-col="{span: 2, offset: 22}">
						<a-button type="primary" size="large" @click="showModal"> 保存 </a-button>
					</a-form-model-item>
				</div>
			</a-form-model>
		</div>

		<a-modal width="600px" title="地图展示" okText="确定" cancelText="取消" :visible.sync="mapVisible" @cancel="mapVisible = false" @ok="mapVisible = false">
			<template slot="footer">
				<a-button type="primary" @click="mapVisible = false"> 确定</a-button>
			</template>
			<div style="width: 100%; height: 600px">
				<iframe id="mapPage" width="100%" height="100%" frameborder="0" src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=4DGBZ-Z273U-FKPVZ-474TQ-T3IJ7-PNBFT&referer=myapp" />
			</div>
		</a-modal>

		<a-modal
			width="600px"
			title="餐厅招牌菜"
			v-model="dishesVisible"
			@ok="dishesConfirm"
			@cancel="
				dishesForm = {}
				$refs.dishesForm.clearValidate()
			">
			<a-form-model ref="dishesForm" :model="dishesForm" :label-col="{span: 6}" :wrapper-col="{span: 16}">
				<a-form-model-item
					label="招牌菜名称"
					prop="name"
					:rules="{
						required: true,
						message: '请输入招牌菜名称'
					}">
					<a-input v-model="dishesForm.name" placeholder="请输入招牌菜名称" />
				</a-form-model-item>
				<a-form-model-item
					label="招牌菜展示"
					prop="picture"
					ref="dishesPicture"
					:rules="{
						required: true,
						message: '请上传招牌菜图片'
					}">
					<img-uploader v-model="dishesForm.picture" @success="$refs.dishesPicture.onFieldBlur()" @remove="$refs.dishesPicture.onFieldBlur()" />
					<template #help>
						<div class="flex text-sm mb-4">
							<div class="text-theme-red mr-2">注:</div>
							<div>
								<p>1、格式必须为jpg/png</p>
								<p>2、建议尺寸：宽460px，高300px</p>
							</div>
						</div>
					</template>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
			<img alt="example" style="width: 100%; margin-top: 20px" :src="previewImage" />
		</a-modal>
	</div>
</template>

<script>
import _ from 'lodash'
import ImgUploader from '@/components/imgUploader'
import VideoUpload from '@/components/videoUpload'
import WangEditor from '@/components/WangEditor'
import {mapState} from 'vuex'
import {Modal} from 'ant-design-vue'
import {getStoreInfo, saveStoreInfo} from '@/api/store'
import {checkDataType, menuNeedObj} from '@/utils/utils'
import Sortable from 'sortablejs'

export default {
	name: 'Store',
	components: {ImgUploader, VideoUpload, WangEditor},
	data() {
		return {
			mapVisible: false,
			week: [
				{i: '1', we: '星期一'},
				{i: '2', we: '星期二'},
				{i: '3', we: '星期三'},
				{i: '4', we: '星期四'},
				{i: '5', we: '星期五'},
				{i: '6', we: '星期六'},
				{i: '0', we: '星期日'}
			],
			form: {
				name: '',
				phone: '',
				logo: '',
				images: [],
				business_time: {
					isSingle: 1,
					start_date: undefined,
					end_date: undefined,
					start_time_am: '',
					end_time_am: '',
					start_time_pm: '',
					end_time_pm: '',
					start_time: '',
					end_time: ''
				},
				address: {
					lat: '',
					lng: '',
					name: ''
				},
				park_position: {
					lat: '',
					lng: '',
					name: ''
				},
				concept: '',
				cpi: '',
				shop_say: '',
				shop_say_picture: '',
				park: '',
				pack_images: [],
				custom_color: '01162B',
				default_order_theme: 0,
				signature_dish_picture: [],
				vr_url: {
					picture: '',
					url: ''
				},
				feie: {
					sn: '', // 打印机编号
					key: '', // 打印机识别码
					copies: '' // 打印份数
				},
				questions: [
					{
						question: '',
						answer: '',
						placeholder: '餐厅有什么特色?'
					},
					{question: '', answer: '', placeholder: '都有什么菜品?'},
					{question: '', answer: '', placeholder: '怎么消费?'}
				],
				video: ''
			},
			rules: {
				title: {
					trigger: ['change', 'blur'],
					required: true,
					message: '请输入店铺名字(不超过15个字符)'
				},
				// phone: {
				//   trigger: ["change", "blur"],
				//   required: true,
				//   whitespace: true,
				//   validator: (rule, value, callback) => {
				//     let tel = /^0\d{2,3}-?\d{7,8}$/;
				//     let phone = /^1([3456789])\d{9}$/;
				//     if (tel.test(value) || phone.test(value)) {
				//       callback();
				//     } else {
				//       callback(new Error());
				//     }
				//   },
				//   message: "请输入正确的联系电话",
				// },
				// category: {
				//   trigger: ["change", "blur"],
				//   required: true,
				//   message: '请选择餐厅类型',
				// },
				// cuisine: {
				//   trigger: ['change', 'blur'],
				//   required: true,
				//   message: '请选择菜系',
				// },
				// business_time: {
				//   start_date: {
				//     trigger: ['change', 'blur'],
				//     required: true,
				//     message: '请选择开始营业日',
				//   },
				//   end_date: {
				//     trigger: ['change', 'blur'],
				//     required: true,
				//     message: '请选择结束营业日',
				//   },
				// },
				// logo: {
				//   trigger: ["change", "blur"],
				//   required: true,
				//   message: "请上传logo",
				// },
				// images: {
				//   trigger: ["change", "blur"],
				//   required: true,
				//   type: "array",
				//   message: "请上传门店照片",
				// },
				// concept: {
				//   trigger: ["change", "blur"],
				//   required: true,
				//   message: "请输入餐厅理念(不超过26个字符)",
				//   max: 26,
				// },
				vr_url: {
					trigger: ['change', 'blur'],
					validator: (rule, value, callback) => {
						if (value.url && !value.picture) {
							callback(new Error('请上传VR缩略图'))
						} else {
							callback()
						}
					}
				},
				shop_say: {
					trigger: ['change', 'blur'],
					validator: (rule, value, callback) => {
						// 不填时
						if (!value && !this.form.shop_say) {
							callback()
						} else {
							// 填了必须上传图片
							if (value && this.form.shop_say) {
								callback()
							} else {
								callback(new Error('请输入餐厅有话说并上传图片'))
							}
						}
					}
				},
				park: {
					trigger: ['change', 'blur'],
					validator: (rule, value, callback) => {
						// 不填时
						if (!value && !this.form.park) {
							callback()
						} else {
							// 填了必须上传图片
							if (value && this.form.park) {
								callback()
							} else {
								callback(new Error('请输入停车指引并上传图片'))
							}
						}
					}
				}
			},
			customColor: [
				{
					name: '暗红色',
					value: '200200',
					color: '#200200',
					theme: {
						shopIndex: [
							{
								name: '头部导航栏',
								index: 0,
								navigationBarBackgroundColor: '#220403',
								navigationBarTextColor: '#FFFFFF',
								navigationBarTextBorderColor: 'rgba(255, 255, 255, 0.2)'
							},
							{
								name: '商家信息背景图',
								index: 1,
								url: 'https://luyopay-djbj.oss-cn-beijing.aliyuncs.com/mini/shopReddishBrown.png'
							},
							{
								name: '商家信息字体颜色',
								index: 2,
								color: '#FFFFFF'
							},
							{
								name: '底部卡片人员信息背景图片',
								index: 3,
								url: 'https://luyopay-djbj.oss-cn-beijing.aliyuncs.com/mini/cardReddishBrown.png'
							},
							{
								name: '底部卡片人员字体颜色',
								index: 4,
								color: '#FFFFFF'
							},
							{
								name: '底部卡片人员预定按钮颜色',
								index: 5,
								color: '#F0E5CF'
							}
						],
						poster: [
							{
								index: 0,
								name: '图例',
								url: 'https://oss.dinggebaojian.com/mini/O9lbZTvEhTUEJdX6zuhI1HvGS4r3mHqr.png'
							},
							{
								index: 1,
								backgroundColor: '#200200'
							},
							{
								index: 2,
								backgroundColor: 'rgba(255,255,255,0.11)'
							},
							{
								index: 3,
								color: '#FFFFFF'
							},
							{
								index: 4,
								color: '#333333',
								backgroundColor: '#FFFFFF'
							},
							{
								index: 5,
								color: '#FFFFFF'
							},
							{
								index: 6,
								url: 'https://oss.dinggebaojian.com/mini/store_poster.png'
							},
							{
								index: 7,
								color: '#FFFFFF'
							},
							{
								index: 8,
								color: '#FFFFFF'
							},
							{
								index: 9,
								color: '#FFFFFF'
							},
							{
								index: 10,
								color: '#FFFFFF'
							},
							{
								index: 11,
								color: '#FFFFFF'
							}
						]
					}
				},
				{
					name: '暗绿色',
					value: '12201B',
					color: '#12201B',
					theme: {
						shopIndex: [
							{
								name: '头部导航栏',
								index: 0,
								navigationBarBackgroundColor: '#12211C',
								navigationBarTextColor: '#FFFFFF',
								navigationBarTextBorderColor: 'rgba(255, 255, 255, 0.2)'
							},
							{
								name: '商家信息背景图',
								index: 1,
								url: 'https://luyopay-djbj.oss-cn-beijing.aliyuncs.com/mini/shopGreen.png'
							},
							{
								name: '商家信息字体颜色',
								index: 2,
								color: '#FFFFFF'
							},
							{
								name: '底部卡片人员信息背景图片',
								index: 3,
								url: 'https://luyopay-djbj.oss-cn-beijing.aliyuncs.com/mini/cardGreen.png'
							},
							{
								name: '底部卡片人员字体颜色',
								index: 4,
								color: '#FFFFFF'
							},
							{
								name: '底部卡片人员预定按钮颜色',
								index: 5,
								color: '#F0E5CF'
							}
						],
						poster: [
							{
								index: 0,
								name: '图例',
								url: 'https://oss.dinggebaojian.com/mini/O9lbZTvEhTUEJdX6zuhI1HvGS4r3mHqr.png'
							},
							{
								index: 1,
								backgroundColor: '#12201B'
							},
							{
								index: 2,
								backgroundColor: 'rgba(255,255,255,0.11)'
							},
							{
								index: 3,
								color: '#FFFFFF'
							},
							{
								index: 4,
								color: '#333333',
								backgroundColor: '#FFFFFF'
							},
							{
								index: 5,
								color: '#FFFFFF'
							},
							{
								index: 6,
								url: 'https://oss.dinggebaojian.com/mini/store_poster.png'
							},
							{
								index: 7,
								color: '#FFFFFF'
							},
							{
								index: 8,
								color: '#FFFFFF'
							},
							{
								index: 9,
								color: '#FFFFFF'
							},
							{
								index: 10,
								color: '#FFFFFF'
							},
							{
								index: 11,
								color: '#FFFFFF'
							}
						]
					}
				},
				{
					name: '深棕色',
					value: '352000',
					color: '#352000',
					theme: {
						shopIndex: [
							{
								name: '头部导航栏',
								index: 0,
								navigationBarBackgroundColor: '#362104',
								navigationBarTextColor: '#FFFFFF',
								navigationBarTextBorderColor: 'rgba(255, 255, 255, 0.2)'
							},
							{
								name: '商家信息背景图',
								index: 1,
								url: 'https://luyopay-djbj.oss-cn-beijing.aliyuncs.com/mini/shopBrown.png'
							},
							{
								name: '商家信息字体颜色',
								index: 2,
								color: '#FFFFFF'
							},
							{
								name: '底部卡片人员信息背景图片',
								index: 3,
								url: 'https://luyopay-djbj.oss-cn-beijing.aliyuncs.com/mini/cardBrown.png'
							},
							{
								name: '底部卡片人员字体颜色',
								index: 4,
								color: '#FFFFFF'
							},
							{
								name: '底部卡片人员预定按钮颜色',
								index: 5,
								color: '#F0E5CF'
							}
						],
						poster: [
							{
								index: 0,
								name: '图例',
								url: 'https://oss.dinggebaojian.com/mini/O9lbZTvEhTUEJdX6zuhI1HvGS4r3mHqr.png'
							},
							{
								index: 1,
								backgroundColor: '#352000'
							},
							{
								index: 2,
								backgroundColor: 'rgba(255,255,255,0.11)'
							},
							{
								index: 3,
								color: '#FFFFFF'
							},
							{
								index: 4,
								color: '#333333',
								backgroundColor: '#FFFFFF'
							},
							{
								index: 5,
								color: '#FFFFFF'
							},
							{
								index: 6,
								url: 'https://oss.dinggebaojian.com/mini/store_poster.png'
							},
							{
								index: 7,
								color: '#FFFFFF'
							},
							{
								index: 8,
								color: '#FFFFFF'
							},
							{
								index: 9,
								color: '#FFFFFF'
							},
							{
								index: 10,
								color: '#FFFFFF'
							},
							{
								index: 11,
								color: '#FFFFFF'
							}
						]
					}
				},
				{
					name: '暗蓝色',
					value: '01162B',
					color: '#01162B',
					theme: {
						shopIndex: [
							{
								name: '头部导航栏',
								index: 0,
								navigationBarBackgroundColor: '#0A1C2D',
								navigationBarTextColor: '#FFFFFF',
								navigationBarTextBorderColor: 'rgba(255, 255, 255, 0.2)'
							},
							{
								name: '商家信息背景图',
								index: 1,
								url: 'https://luyopay-djbj.oss-cn-beijing.aliyuncs.com/mini/shopBlue.png'
							},
							{
								name: '商家信息字体颜色',
								index: 2,
								color: '#FFFFFF'
							},
							{
								name: '底部卡片人员信息背景图片',
								index: 3,
								url: 'https://luyopay-djbj.oss-cn-beijing.aliyuncs.com/mini/cardBlue.png'
							},
							{
								name: '底部卡片人员字体颜色',
								index: 4,
								color: '#FFFFFF'
							},
							{
								name: '底部卡片人员预定按钮颜色',
								index: 5,
								color: '#F0E5CF'
							}
						],
						poster: [
							{
								index: 0,
								name: '图例',
								url: 'https://oss.dinggebaojian.com/mini/O9lbZTvEhTUEJdX6zuhI1HvGS4r3mHqr.png'
							},
							{
								index: 1,
								backgroundColor: '#01162B'
							},
							{
								index: 2,
								backgroundColor: 'rgba(255,255,255,0.11)'
							},
							{
								index: 3,
								color: '#FFFFFF'
							},
							{
								index: 4,
								color: '#333333',
								backgroundColor: '#FFFFFF'
							},
							{
								index: 5,
								color: '#FFFFFF'
							},
							{
								index: 6,
								url: 'https://oss.dinggebaojian.com/mini/store_poster.png'
							},
							{
								index: 7,
								color: '#FFFFFF'
							},
							{
								index: 8,
								color: '#FFFFFF'
							},
							{
								index: 9,
								color: '#FFFFFF'
							},
							{
								index: 10,
								color: '#FFFFFF'
							},
							{
								index: 11,
								color: '#FFFFFF'
							}
						]
					}
				},
				{
					name: '高级灰',
					value: '5B605E',
					color: '#5B605E',
					theme: {
						shopIndex: [
							{
								name: '头部导航栏',
								index: 0,
								navigationBarBackgroundColor: '#5A5F5D',
								navigationBarTextColor: '#FFFFFF',
								navigationBarTextBorderColor: 'rgba(255, 255, 255, 0.2)'
							},
							{
								name: '商家信息背景图',
								index: 1,
								url: 'https://luyopay-djbj.oss-cn-beijing.aliyuncs.com/mini/shopGray.png'
							},
							{
								name: '商家信息字体颜色',
								index: 2,
								color: '#FFFFFF'
							},
							{
								name: '底部卡片人员信息背景图片',
								index: 3,
								url: 'https://luyopay-djbj.oss-cn-beijing.aliyuncs.com/mini/cardGray.png'
							},
							{
								name: '底部卡片人员字体颜色',
								index: 4,
								color: '#FFFFFF'
							},
							{
								name: '底部卡片人员预定按钮颜色',
								index: 5,
								color: '#F0E5CF'
							}
						],
						poster: [
							{
								index: 0,
								name: '图例',
								url: 'https://oss.dinggebaojian.com/mini/O9lbZTvEhTUEJdX6zuhI1HvGS4r3mHqr.png'
							},
							{
								index: 1,
								backgroundColor: '#5B605E'
							},
							{
								index: 2,
								backgroundColor: 'rgba(255,255,255,0.11)'
							},
							{
								index: 3,
								color: '#FFFFFF'
							},
							{
								index: 4,
								color: '#333333',
								backgroundColor: '#FFFFFF'
							},
							{
								index: 5,
								color: '#FFFFFF'
							},
							{
								index: 6,
								url: 'https://oss.dinggebaojian.com/mini/store_poster.png'
							},
							{
								index: 7,
								color: '#FFFFFF'
							},
							{
								index: 8,
								color: '#FFFFFF'
							},
							{
								index: 9,
								color: '#FFFFFF'
							},
							{
								index: 10,
								color: '#FFFFFF'
							},
							{
								index: 11,
								color: '#FFFFFF'
							}
						]
					}
				},
				{
					name: '艳丽红',
					value: 'B81C22',
					color: '#B81C22',
					theme: {
						shopIndex: [
							{
								name: '头部导航栏',
								index: 0,
								navigationBarBackgroundColor: '#B21D22',
								navigationBarTextColor: '#FFFFFF',
								navigationBarTextBorderColor: 'rgba(255, 255, 255, 0.2)'
							},
							{
								name: '商家信息背景图',
								index: 1,
								url: 'https://luyopay-djbj.oss-cn-beijing.aliyuncs.com/mini/shopRed.png'
							},
							{
								name: '商家信息字体颜色',
								index: 2,
								color: '#FFFFFF'
							},
							{
								name: '底部卡片人员信息背景图片',
								index: 3,
								url: 'https://luyopay-djbj.oss-cn-beijing.aliyuncs.com/mini/cardRed.png'
							},
							{
								name: '底部卡片人员字体颜色',
								index: 4,
								color: '#FFFFFF'
							},
							{
								name: '底部卡片人员预定按钮颜色',
								index: 5,
								color: '#F0E5CF'
							}
						],
						poster: [
							{
								index: 0,
								name: '图例',
								url: 'https://oss.dinggebaojian.com/mini/O9lbZTvEhTUEJdX6zuhI1HvGS4r3mHqr.png'
							},
							{
								index: 1,
								backgroundColor: '#B81C22'
							},
							{
								index: 2,
								backgroundColor: 'rgba(255,255,255,0.11)'
							},
							{
								index: 3,
								color: '#FFFFFF'
							},
							{
								index: 4,
								color: '#333333',
								backgroundColor: '#FFFFFF'
							},
							{
								index: 5,
								color: '#FFFFFF'
							},
							{
								index: 6,
								url: 'https://oss.dinggebaojian.com/mini/store_poster.png'
							},
							{
								index: 7,
								color: '#FFFFFF'
							},
							{
								index: 8,
								color: '#FFFFFF'
							},
							{
								index: 9,
								color: '#FFFFFF'
							},
							{
								index: 10,
								color: '#FFFFFF'
							},
							{
								index: 11,
								color: '#FFFFFF'
							}
						]
					}
				},
				{
					name: '米黄色',
					value: 'FEF5E3',
					color: '#FEF5E3',
					theme: {
						shopIndex: [
							{
								name: '头部导航栏',
								index: 0,
								navigationBarBackgroundColor: '#F3DEB5',
								navigationBarTextColor: '#000000',
								navigationBarTextBorderColor: '	rgba(0, 0, 0, 0.1)'
							},
							{
								name: '商家信息背景图',
								index: 1,
								url: 'https://luyopay-djbj.oss-cn-beijing.aliyuncs.com/mini/shopYellow.png'
							},
							{
								name: '商家信息字体颜色',
								index: 2,
								color: '#332B12'
							},
							{
								name: '底部卡片人员信息背景图片',
								index: 3,
								url: 'https://luyopay-djbj.oss-cn-beijing.aliyuncs.com/mini/cardYellow.png'
							},
							{
								name: '底部卡片人员字体颜色',
								index: 4,
								color: '#332B12'
							},
							{
								name: '底部卡片人员预定按钮颜色',
								index: 5,
								color: '#B18544'
							}
						],
						poster: [
							{
								index: 0,
								name: '图例',
								url: 'https://oss.dinggebaojian.com/mini/O9lbZTvEhTUEJdX6zuhI1HvGS4r3mHqr.png'
							},
							{
								index: 1,
								backgroundColor: '#FDF5E3'
							},
							{
								index: 2,
								backgroundColor: '#F6ECD6'
							},
							{
								index: 3,
								color: '#262520'
							},
							{
								index: 4,
								color: '#333333',
								backgroundColor: '#FFFFFF'
							},
							{
								index: 5,
								color: '#333333'
							},
							{
								index: 6,
								url: 'https://oss.dinggebaojian.com/mini/G8C84r6lMUiHxMqys2qZVFffUKKRehIP.png'
							},
							{
								index: 7,
								color: '#333333'
							},
							{
								index: 8,
								color: '#333333'
							},
							{
								index: 9,
								color: '#333333'
							},
							{
								index: 10,
								color: '#333333'
							},
							{
								index: 11,
								color: '#B28544'
							}
						]
					}
				}
			],

			invitationColor: [
				{
					name: '黑金商务',
					value: 0,
					color: '#171415'
				},
				{
					name: '粉红欢快',
					value: 1,
					color: '#CB4D33'
				},
				{
					name: '艳红喜庆',
					value: 2,
					color: '#B41D22'
				},
				{
					name: '蓝灰沉稳',
					value: 3,
					color: '#1F2A3B'
				},
				{
					name: '金色辉煌',
					value: 4,
					color: '#EFD7A8'
				},
				{
					name: '绿意盎然',
					value: 5,
					color: '#3D6137'
				}
			],
			previewVisible: false,
			previewImage: '',

			dishesEdit: false,
			dishesEditIdx: null,
			dishesVisible: false,
			dishesForm: {
				name: '',
				picture: ''
			},
			cuisine_options: [], // 菜品类型
			category_options: [], // 餐厅类型

			id: '', // 富文本ID
			content: '', // 富文本正文
			title: '', // 富文本标题
			primaryPic: '', // 富文本封面
			sharePoster: '', // 富文本分享海报
			editor: null // 富文本对象
		}
	},
	created() {
		this.$nextTick(() => {
			this.$store.dispatch('loading/startContentLoading')
			this.getInfo()
		})
	},
	methods: {
		// 创建排序对象
		createSortable() {
			this.$nextTick(() => {
				const imgTable = document.getElementById('shopSign')
				if (imgTable) {
					new Sortable(imgTable, {
						animation: 150,
						handle: '.imgItem',
						draggable: '.imgItem',
						onSort: ({newIndex, oldIndex}) => {
							let data = this.form.signature_dish_picture
							const oldItem = data[oldIndex]
							data.splice(oldIndex, 1)
							data.splice(newIndex, 0, oldItem)
							this.form.signature_dish_picture = data
						}
					})
				}
			})
		},
		validatorTime() {
			this.$nextTick(() => {
				this.$refs.form.validate().catch(err => err)
			})
		},
		getInfo() {
			getStoreInfo()
				.then(res => {
					if (res.code === 0) {
						this.cuisine_options = (res.data.cuisine_options || []).map(item => menuNeedObj(item, {label: 'name', value: 'name'}))
						this.category_options = (res.data.category_options || []).map(item => menuNeedObj(item, {label: 'name', value: 'name'}))
						// 数据处理-处理停车指引数据
						res.data.park = res.data.park || ''
						res.data.park_position = res.data.park_position || {
							name: '',
							lat: '',
							lng: ''
						}

						if (!res.data.signature_dish_picture) {
							res.data.signature_dish_picture = []
						}

						if (!res.data.vr_url) {
							res.data.vr_url = {picture: '', url: ''}
						}

						if (!res.data.questions) res.data.questions = []
						if (res.data.video && res.data.video_picture) {
							res.data.video = {
								video: res.data.video,
								picture: res.data.video_picture
							}
						}

						if (res.data.questions && res.data.questions?.length === 0) {
							res.data.questions = [
								{
									question: '',
									answer: '',
									placeholder: '餐厅有什么特色?'
								},
								{
									question: '',
									answer: '',
									placeholder: '都有什么菜品?'
								},
								{
									question: '',
									answer: '',
									placeholder: '怎么消费?'
								}
							]
						}

						if (res.data.vr_url && res.data.vr_url.constructor == Array) {
							res.data.vr_url = {}
						}

						res.data.questions = res.data.questions.map(item => {
							if (item.placeholder) return item
							else return {...item, placeholder: '请输入问题'}
						})

						this.form = {...this.form, ...res.data}

						if (checkDataType(res.data.feie, 'object')) {
							this.form.feie = {
								sn: '', // 打印机编号
								key: '', // 打印机识别码
								copies: '', // 打印份数
								...res.data.feie
							}
						} else {
							this.form.feie = {
								sn: '', // 打印机编号
								key: '', // 打印机识别码
								copies: '' // 打印份数
							}
						}

						this.$store.dispatch('loading/endContentLoading')
					} else {
						this.$store.dispatch('loading/endContentLoading')
					}
				})
				.catch(_err => {
					this.$store.dispatch('loading/endContentLoading')
				})
				.finally(() => {
					this.createSortable()
				})
		},
		showModal() {
			this.$refs.form
				.validate()
				.then(() => {
					Modal.confirm({
						title: '提示',
						content: '确认保存信息吗',
						onOk: async next => {
							await this.save()
							next()
						}
					})
				})
				.catch(() => {
					this.$message.error('请正确输入')
				})
		},
		save() {
			this.$message.loading({
				content: '正在保存中！',
				key: 'save',
				duration: 0
			})
			const form = _.cloneDeep(this.form)
			form.password = undefined
			if (form.cpi == null || form.cpi == undefined) form.cpi = ''
			else form.cpi = form.cpi + ''
			// 数据处理-视频
			form.video_picture = form.video?.picture ?? ''
			form.video = form.video?.video ?? ''
			// 数据处理-问答区
			form.questions = form.questions
				.filter(item => item.question)
				.map(item => {
					delete item.placeholder
					return item
				})
			// 小票机信息
			// if (!form.feie.sn && !form.feie.key) {
			// 	delete form.feie
			// }
			// 剔除多余字段
			delete form.id
			delete form.kefu_userid

			saveStoreInfo(form).then(res => {
				if (res.code === 0) {
					this.$message.success({
						content: '修改成功！',
						key: 'save'
					})
					this.getInfo()
					this.$store.dispatch('user/GetInfo')
				}
			})
		},
		/**
		 * @description: 清空停车场地址
		 * @return {*}
		 */
		onDeletePark() {
			this.form.park_position.lng = ''
			this.form.park_position.lat = ''
			this.form.park_position.name = ''
		},
		openMap(type) {
			console.log(type)
			this.mapVisible = true
			window.addEventListener(
				'message',
				event => {
					console.log(event)
					// 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
					const loc = event.data
					if (loc && loc.module === 'locationPicker') {
						// 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
						if (type === 1) {
							this.form.park_position = {
								name: loc.poiaddress,
								lng: loc.latlng.lng,
								lat: loc.latlng.lat
							}
						} else {
							this.form.address = {
								name: loc.poiaddress,
								lng: loc.latlng.lng,
								lat: loc.latlng.lat
							}
						}
					}
				},
				false
			)
		},
		dishesConfirm() {
			this.$refs.dishesForm
				.validate()
				.then(() => {
					// 编辑
					if (this.dishesEdit && this.dishesEditIdx !== null) {
						this.form.signature_dish_picture[this.dishesEditIdx] = _.cloneDeep(this.dishesForm)
					} else {
						// 新增
						this.form.signature_dish_picture.push(_.cloneDeep(this.dishesForm))
					}
					this.dishesEdit = false
					this.dishesEditIdx = null
					this.dishesVisible = false
					this.dishesForm = {}
					this.$refs.dishesForm.clearValidate()
				})
				.catch(err => {
					console.log(err)
				})
				.finally(() => {
					this.createSortable()
				})
		},
		delDish(index) {
			this.form.signature_dish_picture.splice(index, 1)
			this.createSortable()
		},

		// 删除问题
		questionDel(index) {
			this.form.questions.splice(index, 1)
		},

		// 添加问题
		addQuestuin() {
			this.form.questions.push({
				question: '',
				answer: '',
				placeholder: '请输入新问题'
			})
		},
		/**
		 * @description: 商家有话说
		 * @return {*}
		 */
		handleShopSayChange(val) {
			this.form.shop_say = val
		},
		/**
		 * @description: 停车指引
		 * @return {*}
		 * @param {*} val
		 */
		handleParkChange(val) {
			this.form.park = val
		},
		/**
		 * @description: 删除门店视频
		 * @return {*}
		 */
		onRemoveStoreVideo() {
			this.form.video = ''
			this.form.video_picture = ''
		},
		/**
		 * @description: 预览图片
		 * @return {*}
		 * @param {*} file
		 */
		handlePreview(file) {
			this.previewImage = file.url
			this.previewVisible = true
		},
		/**
		 * @description: 招牌菜编辑
		 * @return {*}
		 * @param {*} item
		 */
		handleDishesPreview(item, index) {
			this.dishesEdit = true
			this.dishesEditIdx = index
			this.dishesVisible = true
			// 设置dialog数据
			this.dishesForm = _.cloneDeep(item)
		},
		/**
		 * @description: 修改店名提示
		 * @return {*}
		 */
		onShowTips() {
			this.$message.warning('如需修改门店名称请联系客服！')
		}
	},
	computed: {
		...mapState('loading', {contentLoading: 'contentLoading'})
	}
}
</script>

<style scoped lang="less">
.title-bar {
	height: 56px;
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 4px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
	padding: 0 24px;
}

.title-bar_content {
	display: flex;
	align-items: center;
	color: rgba(0, 0, 0, 0.85);
	font-weight: bold;

	&::before {
		content: '';
		display: inline-block;
		width: 4px;
		height: 16px;
		background: #e63e30;
		margin-right: 12px;
	}
}

.form-content {
	padding: 30px 0 2px;
	.form-item-mark {
		margin-top: 10px;
	}
	.delFormItem {
		::v-deep .ant-form-item-children {
			position: relative;
		}

		.delItem {
			width: 29px;
			height: 29px;
			cursor: pointer;
			position: absolute;
			top: -5px;
			right: -35px;
		}
	}
}

.input_cpi {
	width: 100%;
	position: relative;

	::v-deep .ant-input-number-handler-wrap {
		display: none;
	}
}

.input_cpi::after {
	content: attr(suffix);
	position: absolute;
	top: 3px;
	right: 4px;
}

#allmap {
	margin-top: 10px;
	width: 100%;
	height: 400px;
}

.custom-color-item {
	width: 140px;
	height: 42px;
	background: #ffffff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	font-weight: 400;
	font-size: 16px;
	color: #333333;
	line-height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	user-select: none;
	margin-right: 13px;

	.color-block {
		width: 22px;
		height: 22px;
		border-radius: 11px;
		margin-right: 10px;
	}
}

.custom-color-item-active {
	border: 1px solid #e63e30;
}

.dish-item {
	width: 141px;
	height: 141px;
	padding: 8px;
	margin-right: 12px;
	background: #ffffff;
	border: 1px solid #ececec;
	border-radius: 2px;
	position: relative;

	> img {
		width: 125px;
		height: 125px;
		object-fit: cover;
	}

	.dish-name {
		position: absolute;
		bottom: 0;
		left: 0;
		color: #ffffff;
		height: 26px;
		width: 100%;
		line-height: 26px;
		text-align: center;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 1;
	}

	.dish-item-mask {
		position: absolute;
		z-index: 10;
		opacity: 0;
		transition: opacity 0.3s;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.7);

		&:hover {
			opacity: 1;
		}
	}
}
</style>
